import { FormControl, FormItem, FormLabel, FormMessage } from "@src/ui/form";
import { Textarea } from "@src/ui/textarea";

export const InlineTextAreaField = ({
  label,
  value,
  name,
  onChange,
  onBlur,
  disabled = false,
  placeholder = "",
}: {
  label: string | React.ReactNode;
  value?: string;
  onChange: (...event: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  onBlur: (...event: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  disabled?: boolean;
  placeholder?: string;
}): JSX.Element => {
  return (
    <FormItem className="flex w-full justify-between gap-4">
      <FormLabel className="w-[216px] leading-normal">{label}</FormLabel>
      <FormControl className="w-full flex-1">
        <Textarea
          disabled={disabled}
          rows={10}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};
