import Modal from "@src/deprecatedDesignSystem/components/Modal";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";
import { Button } from "@src/ui/button";
export type AIAssistBaseModalProps = {
  brandSelectOption: BrandSelectOption;
  closeModal: () => void;
  title: string;
  renderForm: (
    closeModal: () => void,
    brandSelectOption: BrandSelectOption,
  ) => JSX.Element;
};

export const AIAssistBaseModal: React.FC<AIAssistBaseModalProps> = ({
  closeModal,
  brandSelectOption,
  title,
  renderForm,
}) => {
  return (
    <Modal
      style={{
        width: "667px",
      }}
      header={
        <TitleHeader
          onCancelClick={closeModal}
          element={
            <AutoLayout
              flex={1}
              direction="horizontal"
              alignmentVertical="center"
            >
              <Text type="P1" fontWeight="SemiBold">
                {title}
              </Text>
            </AutoLayout>
          }
        />
      }
    >
      <AutoLayout
        flex={1}
        direction="vertical"
        alignmentVertical="center"
        spaceBetweenItems={16}
        padding={"0px 24px 24px 24px"}
      >
        <div />
        <AutoLayout
          flex={1}
          direction="horizontal"
          borderRadius={12}
          width="100%"
          spaceBetweenItems={40}
          padding={16}
          style={{
            background:
              "linear-gradient(180deg, rgba(189, 130, 250, 0.10) 0%, rgba(219, 188, 220, 0.10) 100%)",
          }}
        >
          <AutoLayout flex={1} direction="vertical" paddingBottom={10}>
            <Text
              type="P1"
              fontWeight="SemiBold"
              style={{
                lineHeight: "22px",
                paddingBottom: 4,
              }}
            >
              Improve AI results
            </Text>
            <Text
              type="P2"
              color={deprecatedTones.gray10}
              style={{
                whiteSpace: "normal",
              }}
            >
              Add contextual information about your brand to improve the results
              of our AI tools. The more we know, the better the results.
            </Text>
            <a
              href="https://help.opus.so/en/articles/9251014-how-to-customize-ai-assist-for-brands"
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="link" className="pl-0 text-purple-8">
                Learn more about getting AI to understand your brand
              </Button>
            </a>
          </AutoLayout>
          <div
            style={{
              boxShadow: "0px 2.286px 6.857px 0px rgba(0, 0, 0, 0.03)",
              borderRadius: "18px",
              background: "white",
              alignItems: "center",
              display: "flex",
              padding: "6px",
            }}
          >
            {brandSelectOption.logoUrl ? (
              <div
                style={{
                  width: "56px",
                  height: "56px",
                  background: `url(${brandSelectOption.logoUrl}) lightgray 50% / cover no-repeat`,
                  borderRadius: 64,
                }}
              />
            ) : (
              <DeprecatedIcon
                type={"registered-trademark"}
                iconSize={56}
                containerSize={56}
              />
            )}
          </div>
        </AutoLayout>
        {renderForm(closeModal, brandSelectOption)}
      </AutoLayout>
    </Modal>
  );
};
